body {
    font-family: Arial, sans-serif;
    margin: 20px;
}
h2 {
    font-size: 24px;
}
.form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 15px;
}
label {
    font-weight: bold;
}
.mandatory {
    color: white;
    background-color: #006d5b;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 3px;
    margin-left: 5px;
}
input[type="text"], input[type="email"], input[type="tel"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.row {
    display: flex;
    gap: 20px;
    width: 100%;
}
.row div {
    flex: 1;
}
.checkout-btn{
    transition-duration: 500ms;
    width: 100%;
    height: 46px;
    border: none;
    font-size: 16px;
    margin-top: 15px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    background-color: #cb8670;
}
